import '../../App.scss';
import React, { useEffect } from "react";
import Banner from './main-banner/Banner.js';
import Header from './Header/Header.js';

function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Header />
      <Banner />
    </>
  );
}

export default Landing;