import React, { useEffect, useState } from "react";
import "./banner.scss";
import { Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { useHistory } from "react-router-dom";
import useSignature from "../../../hooks/userSign";
import { API_URL } from "../../../utils/ApiUrl";
import Loader from "../../../hooks/loader";
import { Modal } from "react-bootstrap";

const Banner = () => {
  const { login, logout } = useAuth();
  const { account, chainId } = useWeb3React();
  const history = useHistory();
  const { userSign } = useSignature();
  const [loader, setLoader] = useState(false);
  const [error, seterror] = useState('');
  const [user, setUser] = useState(false);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    if (account) {
      // console.log("chain id")
      signInUser();
    }
  }, [account, chainId]);

  const connectMetamaskSignUp = async () => {
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId");
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      login("injected");
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
    }
  };

  const trustWalletSignUp = async () => {
    if (account) {
      await logout("walletconnect");
    } else {
      login("walletconnect");
      localStorage.setItem("connectorId", "walletconnect");
      localStorage.setItem("flag", "true");
    }
  };

  const signInUser = async () => {
    // console.log("innnnnnn")
    let devicee = localStorage.getItem('device')
    if (devicee === 'Safe{Wallet}') {
      console.log(devicee, 'deviceedeviceedevicee');
      if (chainId === 1) {
        let tok = localStorage.getItem("dopToken");
        let addrSign = localStorage.getItem("addrSign");
        if (tok && addrSign === account) {
        } else {
          setLoader(true);
          setMsg("Please check your wallet for signature");
          try {
            let v = await userSign();
            setMsg("");
            var config = {
              method: "post",
              url: `${API_URL}auth/signin/safe`,
              data: {
                walletAddress: account.toLowerCase(),
                sign: v,
              },
            };
            await axios(config)
              .then(function (res) {
                const resData = res?.data?.data;
                console.log(resData, "resData sigin");
                localStorage?.setItem("dopToken", resData?.accessToken);
                localStorage?.setItem("refreshToken", resData?.refreshToken);
                localStorage?.setItem("addrSign", account);
                localStorage?.setItem("signValue", v);
                window.location.assign("/claimall");
                // window.location.reload()
              })
              .catch(function (err) {
                if (err?.response?.data?.statusCode === 403) {
                  seterror(err.response.data.message)
                }
                else {
                  seterror('This wallet is not eligible for any GEMS token claims.')
                }

                // console.log("error here",err.response.data.message)

                setUser(true);
                setLoader(false);
                throw err;
              });
          } catch (err) {
            setLoader(false);
          }
        }
      }
    }
    else {
      console.log(devicee,' esle deviceedeviceedevicee');
      if (chainId === 1) {
        let tok = localStorage.getItem("dopToken");
        let addrSign = localStorage.getItem("addrSign");
        if (tok && addrSign === account) {
        } else {
          setLoader(true);
          setMsg("Please check your wallet for signature");
          try {
            let v = await userSign();
            setMsg("");
            var config = {
              method: "post",
              url: `${API_URL}auth/signin`,
              data: {
                walletAddress: account.toLowerCase(),
                sign: v,
              },
            };
            await axios(config)
              .then(function (res) {
                const resData = res?.data?.data;
                console.log(resData, "resData sigin");
                localStorage?.setItem("dopToken", resData?.accessToken);
                localStorage?.setItem("refreshToken", resData?.refreshToken);
                localStorage?.setItem("addrSign", account);
                localStorage?.setItem("signValue", v);
                window.location.assign("/claimall");
                // window.location.reload()
              })
              .catch(function (err) {
                if (err?.response?.data?.statusCode === 403) {
                  seterror(err.response.data.message)
                }
                else {
                  seterror('This wallet is not eligible for any GEMS token claims.')
                }
                setUser(true);
                setLoader(false);
                throw err;
              });
          } catch (err) {
            setLoader(false);
          }
        }
      }
    }
  };

  return (
    <>
      {loader && <Loader text={msg} />}
      <section className="main-banner">
        <div className="innerbanner">
          <h4 className="bannerhead">Claim your GEMS Tokens & NFTs</h4>
          <p className="bannerpara">
            Welcome! This platform allows you to securely claim your allocated GEMS tokens and NFTs from participating in the GEMS Private Sale.
          </p>
          <p className="connectwalletpara">
            Connect the wallet you used for Private Sale.
          </p>
          <div className="walletmain">
            <div
              onClick={() => {
                connectMetamaskSignUp();
              }}
              // to="/claimall"
              className="walletinner metamaskHide"
            >
              <p className="walletupperpara">Desktop</p>
              <img
                src="\assets\metamask.svg"
                alt="walletimg"
                className="walletimg"
              />
              <h6 className="walletname">MetaMask</h6>
            </div>
            <div
              onClick={() => {
                trustWalletSignUp();
              }}
              className="walletinner"
            >
              <p className="walletupperpara">Mobile</p>
              <img
                src="\assets\walletconnect.svg"
                alt="walletimg"
                className="walletimg"
              />
              <h6 className="walletname">WalletConnect</h6>
            </div>
          </div>
        </div>
      </section>
      <Modal
        className="connectwallet-modal buy-modal"
        show={user}
        onHide={() => setUser(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buy-modal">
            <img src="\assets\error.svg" alt="img" className="img-fluid" />
            <p>{error}</p>
            <button className="btn-common" onClick={() => setUser(false)}>
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Banner;
