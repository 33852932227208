import React, { useEffect, useState } from "react";
import { MerkleTree } from "merkletreejs";
import { ethers } from "ethers";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import keccak256 from "keccak256";
import "./claimall.scss";
import Header from "../landing/Header/Header";
import ClaimDops from "../../hooks/ClaimDop";
import { Accordion, Modal } from "react-bootstrap";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import DopClaim from "../../hooks/isClaimed";
import Loader from "../../hooks/loader";
import ClaimDopsNFT from "../../hooks/ClaimDopNFT";
import DopNFTClaim from "../../hooks/isNftClaimed";
import PurchaseModal from "../DataTables/purchaseDetail";
import PurchaseNftModal from "../DataTables/purchaseNftDetail";
import { API_URL } from "../../utils/ApiUrl";
import { setupNetwork } from "../../utils/wallet";

const Claimall = () => {
  useEffect(() => {
    document.body.style.overflow = "auto";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  const history = useHistory();
  const { Claim } = ClaimDops();
  const { account, chainId } = useWeb3React();
  const { IsClaimed } = DopClaim();
  const { IsNFTClaimed } = DopNFTClaim();
  const { ClaimNFT } = ClaimDopsNFT();
  const [claimToken, setClaimToken] = useState(true);
  const [error, setError] = useState("");
  const [claimNFT, setClaimNFT] = useState(false);
  const [onlyTok, setOnlyTok] = useState(false);
  const [terms, setTerms] = useState(false);
  const [tree, setTree] = useState(null);
  const [profile, setProfile] = useState(null);
  const [addObj, setAddObj] = useState(null);
  const [addObjNFT, setAddObjNFT] = useState(null);
  const [total, setTotal] = useState(0);
  const [tokenCl, setTokenCl] = useState(false);
  const [isKycVer, setIsKycVer] = useState(true);
  const [nftCl, setNftCl] = useState(false);
  const [nftLeaves, setNftLeaves] = useState(null);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const [pages1, setPages1] = useState([]);
  const [pageCount1, setPageCount1] = useState(0);
  const [page1, setPage1] = useState(0);
  const [count1, setCount1] = useState(0);
  const [arry, setArry] = useState([]);
  const [arry1, setArry1] = useState([]);

  // nft purchase lists
  const [isNfts, setIsNfts] = useState(false);
  const [nft200, setNft200] = useState([]);
  const [nft500, setNft500] = useState([]);
  const [nft1000, setNft1000] = useState([]);
  const [nft5000, setNft5000] = useState([]);
  const [nft10000, setNft10000] = useState([]);
  const [nft30000, setNft30000] = useState([]);
  const [nft75000, setNft75000] = useState([]);
  const [nftDopss, setNftDops] = useState(0);
  const [nftLengths, setNftLengths] = useState(0);
  const [detailTransactions, setDetailTransactios] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [airdropAmount, setAirdropAmount] = useState(null);
  const [earnOn, setEarnOn] = useState(null);

  useEffect(() => {
    if (ethers && account) {
      const addr = [
        {
          address: "0x22dA5C97F742eC3828a8183896dA56B44eFd0471",
          quantity: 50000000000000000000n,
          ids: [0, 1],
          nftQuantity: [1, 2],
          isKycRequired: true,
        },
        {
          address: "0x96d2cc9E3c1Ea954aa67Db266a0a79F06E007012",
          quantity: 150000000000000000000n,
          ids: [0, 1],
          nftQuantity: [1, 2],
          isKycRequired: false,
        },
        {
          address: "0x53780eDC8E2a5236aD7aeBA183C60672C602438A",
          quantity: 150000000000000000000n,
          ids: [0, 1],
          nftQuantity: [1, 2],
          isKycRequired: false,
        },
        {
          address: "0x2d2234950EA9809faDFc2E234435CD56c96b29aE",
          quantity: 150000000000000000000n,
          ids: [0, 1],
          nftQuantity: [1, 2],
          isKycRequired: true,
        },
      ];

      const leaves = addr.map((x) =>
        ethers.utils.solidityKeccak256(
          ["address", "uint256", "uint256[]", "uint256[]", "bool"],
          [x.address, x.quantity, x.ids, x.nftQuantity, x.isKycRequired]
        )
      );

      const tree1 = new MerkleTree(leaves, keccak256, { sort: true });
      // setTree(tree1);
      const proofs = leaves.map((leaf) => tree1.getHexProof(leaf));
      const root = tree1.getHexRoot();

      // NFT leave logic
      let myData = addr?.filter((i) => {
        return i?.address === account;
      });

      console.log("myData", myData, account);

      if (myData?.length > 0) {
        const leaves0 = myData.map((x) =>
          ethers.utils.solidityKeccak256(
            ["address", "uint256", "uint256[]", "uint256[]", "bool"],
            [x.address, x.quantity, x.ids, x.nftQuantity, x.isKycRequired]
          )
        );
        console.log("leaves0", leaves0);
        const proofs = tree1.getHexProof(leaves0[0]);
        console.log("proofs", proofs);
        setNftLeaves(proofs);
      }
      console.log("tree", tree1);
      console.log("leavesnode", proofs);
      console.log("root", root);
    }
  }, [ethers, account]);

  const isDopClaimed = async () => {
    try {
      setLoader(true);
      const res = await IsClaimed(account);
      if (res) {
        setTokenCl(res);
      } else {
        setTokenCl(false);
      }
      setLoader(false);
    } catch (err) {
      console.log("errxxxxx", err);
      // history.push("/");
      // window.location.reload();
    }
    setLoader(false);
  };

  useEffect(() => {
    if (chainId !== 1) {
      // chainSwitch();
    }
  }, [chainId]);

  // const getProofs = async () => {
  //   let token = localStorage.getItem("dopToken");
  //   setLoader(true);
  //   let apiResponse = await axios.get(`${API_URL}claim/proof`, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });

  //   // handle success

  //   // console.log("!!!!!!", proofs);
  //   const proofs = apiResponse?.data?.data?.merkleProof;
  //   let v = apiResponse?.data?.data?.sign?.v;
  //   let r = apiResponse?.data?.data?.sign?.r;
  //   let s = apiResponse?.data?.data?.sign?.s;
  //   if (s) {
  //     await ClaimYourDops(proofs, v, r, s, apiResponse);
  //   }
  // };

  const ClaimYourDops = async () => {
    try {
      if (onlyTok === false) {
        setOnlyTok(true);
      } else {
        let token = localStorage.getItem("dopToken");
        setLoader(true);
        let apiResponse = await axios.get(`${API_URL}claim/proof`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // handle success

        // console.log("!!!!!!", proofs);
        const proofs = apiResponse?.data?.data?.merkleProof;
        let v = apiResponse?.data?.data?.sign?.v;
        let r = apiResponse?.data?.data?.sign?.r;
        let s = apiResponse?.data?.data?.sign?.s;
        if (s) {
          try {
            const res = await Claim(
              apiResponse?.data?.data?.proofData?.amount?.toString(),
              account,
              proofs,
              apiResponse?.data?.data?.proofData?.ids,
              apiResponse?.data?.data?.proofData?.nftQuantity,
              apiResponse?.data?.data?.proofData?.isKycRequired,
              claimNFT,
              v,
              r,
              s,
              () => {
                setLoader(false);
                setTokenCl(true);
                handleShow();
              }
            );

            console.log("res", res);
            // if (res) {
            //   setLoader(false);
            //   setTokenCl(true);
            //   handleShow();
            // }
            setLoader(false);
          } catch (err) {
            setLoader(false);
            // handle error
            console.log(err, "err on clai");
          }
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const setupTokens = () => {
    const addr = [
      {
        address: "0x22dA5C97F742eC3828a8183896dA56B44eFd0471",
        quantity: 50000000000000000000n,
        ids: [0, 1],
        nftQuantity: [1, 2],
        isKycRequired: true,
      },
      {
        address: "0x96d2cc9E3c1Ea954aa67Db266a0a79F06E007012",
        quantity: 150000000000000000000n,
        ids: [0, 1],
        nftQuantity: [1, 2],
        isKycRequired: false,
      },
      {
        address: "0x53780eDC8E2a5236aD7aeBA183C60672C602438A",
        quantity: 150000000000000000000n,
        ids: [0, 1],
        nftQuantity: [1, 2],
        isKycRequired: false,
      },
      {
        address: "0x2d2234950EA9809faDFc2E234435CD56c96b29aE",
        quantity: 150000000000000000000n,
        ids: [0, 1],
        nftQuantity: [1, 2],
        isKycRequired: true,
      },
    ];
    let leaves = addr.find((x) => x.address === account);
    if (leaves) {
      setAddObj(leaves);
    }
    let tota = 0;
    let filts = addr.filter((r) => {
      return r.address === account;
    });
    for (let i of filts) {
      tota = i.quantity;
    }
    setTotal(tota);
  };

  useEffect(() => {
    if (account) {
      setupTokens();
    }
  }, [account]);

  //   Handling pagination below
  const handlePageChange = (e) => {
    console.log("called");
    const selectedPage = e.selected;
    setPage(selectedPage);
  };
  const handlePageChange1 = (e) => {
    console.log("called");
    const selectedPage = e.selected;
    setPage1(selectedPage);
  };

  useEffect(() => {
    if (account) {
      getProfile();
      isDopClaimed();
    }
  }, [account]);
  useEffect(() => {
    if (account) {
      dopTransaction();
    }
  }, [page, account]);
  useEffect(() => {
    if (account) {
      NftTransaction();
      NftTransactionDetails();
    }
  }, [page1, account]);

  const getProfile = async () => {
    let token = localStorage.getItem("dopToken");
    // setLoader(true);
    var config = {
      method: "get",
      url: `${API_URL}users/profile`,

      headers: {
        Authorization: "Bearer " + token,
      },
      data: {},
    };
    await axios(config)
      .then(function (res) {
        const resss = res?.data.data;
        let date = new Date(resss?.rewardCreatedAt).toLocaleDateString();
        // setAirdropAmount(resss?.airdropAmount);
        setProfile(resss);
        if (resss?.kycRequired === false) {
          setIsKycVer("approved");
        } else {
          setIsKycVer(resss?.kycStatus);
        }
        setEarnOn(date);
        setLoader(false);
      })
      .catch(function (err) {
        // if (err?.response?.data?.statusCode === 401) {
        //   refreshTokeen();
        // } else {
        //   setLoader(false);
        // }
        setLoader(false);
      });
  };
  const refreshTokeen = async () => {
    let retoken = localStorage.getItem("refreshToken");
    var config = {
      method: "post",
      url: `${API_URL}auth/refresh-token`,
      data: {
        refreshToken: retoken,
      },
    };

    await axios(config)
      .then(function (res) {
        const resData = res?.data?.data;
        localStorage?.setItem("dopToken", resData?.accessToken);
        localStorage?.setItem("refreshToken", resData?.refreshToken);
        // setLoader(false)
        getProfile();
        dopTransaction();
        NftTransaction();
      })
      .catch(function (err) {
        // diconnect()
        localStorage.clear();
        history.push("/");
        setLoader(false);
        throw err;
      });
  };
  const dopTransaction = async () => {
    let token = localStorage.getItem("dopToken");
    // setLoader(true);
    var config = {
      method: "get",
      url: `${API_URL}transactions/dop-transactions?limit=${5}&offset=${page + 1
        }`,

      headers: {
        Authorization: "Bearer " + token,
      },
      data: {},
    };
    await axios(config)
      .then(function (res) {
        const resss = res?.data.data;
        setArry(resss?.transactions);
        console.log(resss, "doptrasection");
        setCount(resss.count);
        let arr = Array.from(Array(parseInt(resss.pages)).keys());
        setPageCount(resss.pages);
        setPages(arr);
        setLoader(false);
      })
      .catch(function (err) {
        setLoader(false);
        // if (err?.response?.data?.statusCode === 401) {
        //   refreshTokeen();
        // } else {
        //   setLoader(false);
        // }
      });
  };
  const NftTransaction = async () => {
    let token = localStorage.getItem("dopToken");
    // setLoader(true);
    var config = {
      method: "get",
      url: `${API_URL}transactions/nft-transactions?limit=${5}&offset=${page1 + 1
        }`,

      headers: {
        Authorization: "Bearer " + token,
      },
      data: {},
    };
    await axios(config)
      .then(function (res) {
        const resss = res?.data.data;
        console.log(resss, "dopnfttrasection");
        setCount1(resss.count);
        setArry1(resss?.transactions);
        let arr = Array.from(Array(parseInt(resss.pages)).keys());
        setPageCount1(resss.pages);
        setPages1(arr);
        setLoader(false);
      })
      .catch(function (err) {
        setLoader(false);

        // if (err?.response?.data?.statusCode === 401) {
        //   refreshTokeen();
        // } else {
        //   setLoader(false);
        // }
      });
  };

  const NftTransactionDetails = async () => {
    let token = localStorage.getItem("dopToken");
    var config = {
      method: "get",
      url: `${API_URL}transactions/nft-transactions?limit=${500}&offset=${page1 + 1
        }`,

      headers: {
        Authorization: "Bearer " + token,
      },
      data: {},
    };
    await axios(config)
      .then(function (res) {
        const transactions = res?.data?.data?.transactions;
        let dum200 = [];
        let dum500 = [];
        let dum1000 = [];
        let dum5000 = [];
        let dum10000 = [];
        let dum30000 = [];
        let dum75000 = [];
        let nftLength = {
          l200: 0,
          l500: 0,
          l1000: 0,
          l5000: 0,
          l10000: 0,
          l30000: 0,
          l75000: 0,
        };
        if (transactions?.length > 0) {
          setIsNfts(true);
          for (let i of transactions) {
            if (i?.nftAmounts[0] !== "0") {
              nftLength.l200 = nftLength.l200 + parseInt(i?.nftAmounts[0]);
              dum200?.push(i);
            }
            if (i?.nftAmounts[1] !== "0") {
              nftLength.l500 = nftLength.l500 + parseInt(i?.nftAmounts[1]);
              dum500?.push(i);
            }
            if (i?.nftAmounts[2] !== "0") {
              nftLength.l1000 = nftLength.l1000 + parseInt(i?.nftAmounts[2]);
              dum1000?.push(i);
            }
            if (i?.nftAmounts[3] !== "0") {
              nftLength.l5000 = nftLength.l5000 + parseInt(i?.nftAmounts[3]);
              dum5000?.push(i);
            }
            if (i?.nftAmounts[4] !== "0") {
              nftLength.l10000 = nftLength.l10000 + parseInt(i?.nftAmounts[4]);
              dum10000?.push(i);
            }
            if (i?.nftAmounts[5] !== "0") {
              nftLength.l30000 = nftLength.l30000 + parseInt(i?.nftAmounts[5]);
              dum30000?.push(i);
            }
            if (i?.nftAmounts[6] !== "0") {
              nftLength.l75000 = nftLength.l75000 + parseInt(i?.nftAmounts[6]);
              dum75000?.push(i);
            }
          }
          setNft200(dum200);
          setNft500(dum500);
          setNft1000(dum1000);
          setNft5000(dum5000);
          setNft10000(dum10000);
          setNft30000(dum30000);
          setNft75000(dum75000);
          setNftLengths(nftLength);
        } else {
          setIsNfts(false);
        }
        let nftDops = {
          total200: 0,
          total500: 0,
          total1000: 0,
          total5000: 0,
          total10000: 0,
          total30000: 0,
          total75000: 0,
        };
        if (dum200?.length > 0) {
          let tota = 0;
          for (let i of dum200) {
            tota = tota + 200 / i?.price;
          }
          nftDops.total200 = tota;
        }
        if (dum500?.length > 0) {
          let tota = 0;
          for (let i of dum500) {
            tota = tota + 500 / i?.price;
          }
          nftDops.total500 = tota;
        }
        if (dum1000?.length > 0) {
          let tota = 0;
          for (let i of dum1000) {
            tota = tota + 1000 / i?.price;
          }
          nftDops.total1000 = tota;
        }
        if (dum5000?.length > 0) {
          let tota = 0;
          for (let i of dum5000) {
            tota = tota + 5000 / i?.price;
          }
          nftDops.total5000 = tota;
        }
        if (dum10000?.length > 0) {
          let tota = 0;
          for (let i of dum10000) {
            tota = tota + 10000 / i?.price;
          }
          nftDops.total10000 = tota;
        }
        if (dum30000?.length > 0) {
          let tota = 0;
          for (let i of dum30000) {
            tota = tota + 30000 / i?.price;
          }
          nftDops.total30000 = tota;
        }
        if (dum75000?.length > 0) {
          let tota = 0;
          for (let i of dum75000) {
            tota = tota + 75000 / i?.price;
          }
          nftDops.total75000 = tota;
        }
        setNftDops(nftDops);
        console.log(transactions, "transactions");
      })
      .catch(function (err) {
        setLoader(false);

        // if (err?.response?.data?.statusCode === 401) {
        //   refreshTokeen();
        // } else {
        //   setLoader(false);
        // }
      });
  };

  const chainSwitch = async () => {
    let b = await setupNetwork(1);
  };

  return (
    <>
      <Header />
      {loader && <Loader />}
      <section className="claimallmain">
        <div className="custom-container">
          <h5 className="claimallinnerhead">Claim</h5>
          <div className="claimallinner">
            <div className="claimallleft">
              <div className="claimallleftinner">
                <h6 className="claimalllefthead">Tokens</h6>
                <div
                  onClick={() => {
                    if (arry?.length > 0) {
                      handleShow2();
                    }
                  }}
                  className="claimtoken"
                >
                  <div className="claimtokenleft">
                    <div className="claimtokenimg">
                      <img
                        src="\assets\cardimages\tokens.svg"
                        alt="claiminnerimg"
                        className="claiminnerimg"
                      />
                    </div>
                    <h6 className="tokenlefthead">GEMS Tokens</h6>
                    {arry?.length > 0 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                          fill="#797979"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                  </div>
                  {profile?.dopPurchased ? (
                    <h6 className="claimtokenrighthead">
                      {(
                        profile?.dopPurchased - profile?.dopPurchasedByNft
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      GEMS
                    </h6>
                  ) : (
                    <h6 className="claimtokenrighthead">{0.0} GEMS</h6>
                  )}
                </div>
                {/* <div
                  onClick={() => {
                    if (airdropAmount > 0) {
                      handleShow3();
                    }
                  }}
                  className="claimtoken"
                >
                  <div className="claimtokenleft">
                    <div className="claimtokenimg">
                      <img
                        src="\assets\cardimages\rewards.svg"
                        alt="claiminnerimg"
                        className="claiminnerimg"
                      />
                    </div>
                    <h6 className="tokenlefthead">Testnet Rewards</h6>
                    {airdropAmount > 0 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                          fill="#797979"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                  </div>
                  {profile?.airdropAmount ? (
                    <h6 className="claimtokenrighthead">
                      {profile?.airdropAmount?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      GEMS
                    </h6>
                  ) : (
                    <h6 className="claimtokenrighthead">{0.0} GEMS</h6>
                  )}
                </div> */}
              </div>
              {
                isNfts ? (
                  <div className="claimallleftinner">
                    <h6 className="claimalllefthead">NFTs</h6>
                    {/* <div className="claimtoken" onClick={handleShow4}>
                      <div className="claimtokenleft">
                        <div className="claimtokenimg">
                          <img
                            src="\assets\cardimages\nftone.svg"
                            alt="claiminnerimg"
                            className="claiminnerimg"
                          />
                        </div>
                        <h6 className="tokenlefthead">$200 GEMS NFT</h6>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                            fill="#797979"
                          />
                        </svg>
                      </div>
                      <h6 className="claimtokenrighthead">
                        50,000.00 DOP <br />
                        <span className="xspan">x2 NFTS</span>
                      </h6>
                    </div> */}
                    {nft200?.length > 0 && (
                      <div
                        className="claimtoken"
                        onClick={() => {
                          setDetailTransactios(nft200);
                          handleShow4();
                        }}
                      >
                        <div className="claimtokenleft">
                          <div className="claimtokenimg">
                            <img
                              src="\assets\cardimages\nftone.svg"
                              alt="claiminnerimg"
                              className="claiminnerimg"
                            />
                          </div>
                          <h6 className="tokenlefthead">$200 GEMS NFT</h6>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                              fill="#797979"
                            />
                          </svg>
                        </div>
                        <h6 className="claimtokenrighthead">
                          {nftDopss?.total200?.toFixed(4)} GEMS <br />
                          <span className="xspan">
                            x{nftLengths?.l200} NFTS
                          </span>
                        </h6>
                      </div>
                    )}
                    {nft500?.length > 0 && (
                      <div
                        className="claimtoken"
                        onClick={() => {
                          setDetailTransactios(nft500);
                          handleShow4();
                        }}
                      >
                        <div className="claimtokenleft">
                          <div className="claimtokenimg">
                            <img
                              src="\assets\cardimages\nfttwo.svg"
                              alt="claiminnerimg"
                              className="claiminnerimg"
                            />
                          </div>
                          <h6 className="tokenlefthead">$500 GEMS NFT</h6>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                              fill="#797979"
                            />
                          </svg>
                        </div>
                        <h6 className="claimtokenrighthead">
                          {nftDopss?.total500?.toFixed(4)} GEMS <br />
                          <span className="xspan">
                            x{nftLengths?.l500} NFTS
                          </span>
                        </h6>
                      </div>
                    )}
                    {nft1000?.length > 0 && (
                      <div
                        className="claimtoken"
                        onClick={() => {
                          setDetailTransactios(nft1000);
                          handleShow4();
                        }}
                      >
                        <div className="claimtokenleft">
                          <div className="claimtokenimg">
                            <img
                              src="\assets\cardimages\nftthree.svg"
                              alt="claiminnerimg"
                              className="claiminnerimg"
                            />
                          </div>
                          <h6 className="tokenlefthead">$1,000 GEMS NFT</h6>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                              fill="#797979"
                            />
                          </svg>
                        </div>
                        <h6 className="claimtokenrighthead">
                          {nftDopss?.total1000?.toFixed(4)} GEMS <br />
                          <span className="xspan">
                            x{nftLengths?.l1000} NFTS
                          </span>
                        </h6>
                      </div>
                    )}
                    {nft5000?.length > 0 && (
                      <div
                        className="claimtoken"
                        onClick={() => {
                          setDetailTransactios(nft5000);
                          handleShow4();
                        }}
                      >
                        <div className="claimtokenleft">
                          <div className="claimtokenimg">
                            <img
                              src="\assets\cardimages\nftfour.svg"
                              alt="claiminnerimg"
                              className="claiminnerimg"
                            />
                          </div>
                          <h6 className="tokenlefthead">$5,000 GEMS NFT</h6>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                              fill="#797979"
                            />
                          </svg>
                        </div>
                        <h6 className="claimtokenrighthead">
                          {nftDopss?.total5000?.toFixed(4)} GEMS <br />
                          <span className="xspan">
                            x{nftLengths?.l5000} NFTS
                          </span>
                        </h6>
                      </div>
                    )}
                    {nft10000?.length > 0 && (
                      <div
                        className="claimtoken"
                        onClick={() => {
                          setDetailTransactios(nft10000);
                          handleShow4();
                        }}
                      >
                        <div className="claimtokenleft">
                          <div className="claimtokenimg">
                            <img
                              src="\assets\cardimages\nftfive.svg"
                              alt="claiminnerimg"
                              className="claiminnerimg"
                            />
                          </div>
                          <h6 className="tokenlefthead">$10,000 GEMS NFT</h6>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                              fill="#797979"
                            />
                          </svg>
                        </div>
                        <h6 className="claimtokenrighthead">
                          {nftDopss?.total10000?.toFixed(4)} GEMS <br />
                          <span className="xspan">
                            x{nftLengths?.l10000} NFTS
                          </span>
                        </h6>
                      </div>
                    )}
                    {nft30000?.length > 0 && (
                      <div
                        className="claimtoken"
                        onClick={() => {
                          setDetailTransactios(nft30000);
                          handleShow4();
                        }}
                      >
                        <div className="claimtokenleft">
                          <div className="claimtokenimg">
                            <img
                              src="\assets\cardimages\nftsix.svg"
                              alt="claiminnerimg"
                              className="claiminnerimg"
                            />
                          </div>
                          <h6 className="tokenlefthead">$30,000 GEMS NFT</h6>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                              fill="#797979"
                            />
                          </svg>
                        </div>
                        <h6 className="claimtokenrighthead">
                          {nftDopss?.total30000?.toFixed(4)} GEMS <br />
                          <span className="xspan">
                            x{nftLengths?.l30000} NFTS
                          </span>
                        </h6>
                      </div>
                    )}
                    {nft75000?.length > 0 && (
                      <div
                        className="claimtoken"
                        onClick={() => {
                          setDetailTransactios(nft75000);
                          handleShow4();
                        }}
                      >
                        <div className="claimtokenleft">
                          <div className="claimtokenimg">
                            <img
                              src="\assets\cardimages\nftseven.svg"
                              alt="claiminnerimg"
                              className="claiminnerimg"
                            />
                          </div>
                          <h6 className="tokenlefthead">$75,000 GEMS NFT</h6>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.905 8.52 13.0007 8.28267 13 8C13 7.71667 12.904 7.47933 12.712 7.288C12.52 7.09667 12.2827 7.00067 12 7C11.7167 7 11.4793 7.096 11.288 7.288C11.0967 7.48 11.0007 7.71733 11 8C11 8.28333 11.096 8.521 11.288 8.713C11.48 8.905 11.7173 9.00067 12 9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                              fill="#797979"
                            />
                          </svg>
                        </div>
                        <h6 className="claimtokenrighthead">
                          {nftDopss?.total75000?.toFixed(4)} GEMS <br />
                          <span className="xspan">
                            x{nftLengths?.l75000} NFTS
                          </span>
                        </h6>
                      </div>
                    )}
                    <div className="checkboxmain">
                      <div class="content">
                        <label className="checkBox">
                          <input
                            type="checkbox"
                            id="ch"
                            checked={claimToken}
                            onChange={() => {
                              setClaimToken(!claimToken);
                              setClaimNFT(false);
                            }}
                          />
                          <div class="transition"></div>
                        </label>
                      </div>
                      <div className="checkboxtexts">
                        <h6 className="checkboxhead">
                          claim ONLY GEMS tokens{" "}
                          <span className="greenspan">Lower Gas Fee</span>
                        </h6>
                        <p className="checkboxpara">
                          CLAIM your GEMS tokens associated with NFTS
                        </p>
                      </div>
                    </div>
                    <div className="checkboxmain">
                      <div class="content">
                        <label className="checkBox">
                          <input
                            type="checkbox"
                            id="ch"
                            checked={claimNFT}
                            onChange={() => {
                              setClaimNFT(!claimNFT);
                              setClaimToken(false);
                            }}
                          />
                          <div class="transition"></div>
                        </label>
                      </div>
                      <div className="checkboxtexts">
                        <h6 className="checkboxhead">
                          mint nft & claim GEMS tokens{" "}
                          <span className="redspan"> Higher Gas Fee</span>
                        </h6>
                        <p className="checkboxpara">
                          mint NFT and also receive your GEMS tokens associated
                          with it
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
                // (
                //   <p
                //     style={{
                //       color: "#fff",
                //       textAlign: "center",
                //       fontSize: 16,
                //       marginTop: 32,
                //     }}
                //   >
                //     No Nft's found!
                //   </p>
                // )
              }
            </div>
            <div className="bottom-twice-elem">
              <div className="claimallright">
                <h6 className="claimallrighthead">Summary</h6>
                <div className="summarymain">
                  <p className="summarypara">GEMS Tokens</p>
                  {profile?.dopPurchased ? (
                    <h6 className="summaryhead">
                      {(
                        profile?.dopPurchased
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      GEMS
                    </h6>
                  ) : (
                    <h6 className="summaryhead">{0.0} GEMS</h6>
                  )}
                </div>
                {/* <div className="summarymain">
                <p className="summarypara">Testnet Rewards</p>
                <h6 className="summaryhead">
                  {profile?.airdropAmount ? (
                    <h6 className="summaryhead">
                      {profile?.airdropAmount.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      GEMS
                    </h6>
                  ) : (
                    <h6 className="summaryhead">{0.0} GEMS</h6>
                  )}{" "}
                </h6>
              </div> */}
                {claimNFT && (
                  ""
                  // <div class="summarymain">
                  //   <p class="summarypara">GEMS NFTs</p>
                  //   <h6 class="summaryhead">14 GEMS NFTs</h6>
                  // </div>
                )}
                <div className="summarymain">
                  <p className="summarypara">GEMS tokens associated with NFTs</p>
                  <h6 className="summaryhead">
                    {profile?.dopPurchasedByNft ? (
                      <h6 className="summaryhead">
                        {profile?.dopPurchasedByNft.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        GEMS
                      </h6>
                    ) : (
                      <h6 className="summaryhead">{0.0} GEMS</h6>
                    )}{" "}
                  </h6>
                </div>
                <div className="totalmain">
                  <p className="totalpara">TOTAL</p>
                  {/* {profile?.airdropAmount ? ( */}
                  <h6 className="totalhead">
                    {(
                      profile?.dopPurchased + profile?.dopPurchasedByNft
                    )?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    GEMS
                  </h6>
                  {/* ) : (
                  <h6 className="totalhead">0.00</h6>
                )} */}
                </div>
                {claimNFT && (
                  <div class="checkboxmain">
                    <div class="content">
                      <label class="checkBox">
                        <input
                          onChange={() => {
                            setTerms(!terms);
                            setError("");
                          }}
                          checked={terms}
                          type="checkbox"
                          id="ch"
                        />
                        <div class="transition"></div>
                      </label>
                    </div>
                    <p class="maincheckboxpara">
                      By checking this box, I affirm and warrant that I am not
                      identified as a sanctioned individual (or any equivalent
                      designation) on any sanctions, blacklist, or analogous
                      register in any global jurisdiction.
                    </p>
                  </div>
                )}
                <p
                  style={{
                    color: "red",
                    textAlign: "center",
                    marginBottom: 20,
                    marginTop: -10,
                    fontSize: 14,
                  }}
                >
                  {error}
                </p>

                {/* nftCl */}
                {claimNFT ? (
                  <button
                    disabled={tokenCl}
                    style={{ opacity: tokenCl ? 0.5 : 1 }}
                    onClick={() => ClaimYourDops()}
                    className={
                      "claimbtn " + (tokenCl ? "disable" : "")
                    }
                  >
                    {tokenCl ? "claimed" : "claim"}
                  </button>
                ) : (
                  <button
                    disabled={tokenCl}
                    style={{ opacity: tokenCl ? 0.5 : 1 }}
                    onClick={() => ClaimYourDops()}
                    className={
                      "claimbtn " + (tokenCl ? "disable" : "")
                    }
                  >
                    {tokenCl ? "claimed" : "claim"}
                  </button>
                )}
              </div>
              {claimToken === true && isNfts &&
                (
                  <div className="important-div">
                    <h6>Important Notice</h6>
                    <p>Please be aware that once you finalize this claim process, you will no longer have the opportunity to mint your NFTs at a later time.</p>
                  </div>
                )
              }

            </div>
          </div>
        </div>
      </section>

      <Modal
        className="connectwallet-modal buy-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Claimed Successfully</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buy-modal">
            <img src="\assets\happy.svg" alt="img" className="img-fluid" />
            <p>Your claim has been processed successfully.</p>
            <button className="btn-common" onClick={handleClose}>
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="connectwallet-modal buy-modal"
        show={onlyTok === true && isNfts === true}
        onHide={() => setOnlyTok(null)}
        // onHide={() => {
        //   setOnlyTok(null);
        //   ClaimYourDops();
        // }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buy-modal">
            <img src="\assets\error.svg" alt="img" className="img-fluid" />
            <p>
              You will not be able to claim nfts if you have nfts and you don't
              want to claim with tokens
            </p>
            <button className="btn-common" onClick={() => setOnlyTok(null)}>
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="connectwallet-modal buy-modal"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buy-modal">
            <img src="\assets\error.svg" alt="img" className="img-fluid" />
            <p>
              {total > 0
                ? "Oops, something went wrong."
                : `This wallet is not eligible for any GEMS token claims.`}
            </p>
            <button className="btn-common" onClick={handleClose1}>
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="connectwallet-modal buy-modal tablemodal"
        show={show2}
        onHide={handleClose2}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Token Purchase Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PurchaseModal
            pages={pages}
            pageCount={pageCount}
            page={page}
            handlePageChange={handlePageChange}
            arryy={arry}
            count={count}
          />
        </Modal.Body>
      </Modal>

      {/* <Modal
        className="connectwallet-modal buy-modal tablemodal"
        show={show3}
        onHide={handleClose3}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Testnet Rewards Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="mainrewardsdiv">
            <div class="rewardmain">
              <p class="rewardinnerpara">earned on</p>
              <p class="rewardinnerpara">GEMS amount earned</p>
            </div>
            <div class="rewardmain">
              <p class="rewardinnerpara">{earnOn}</p>
              <p class="rewardinnerpara">{airdropAmount?.toFixed(2)} GEMS</p>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      <Modal
        className="connectwallet-modal buy-modal tablemodal"
        show={show4}
        onHide={handleClose4}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>GEMS NFTs Purchase Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PurchaseNftModal
            pages={pages1}
            pageCount={pageCount1}
            page={page1}
            handlePageChange={handlePageChange1}
            arryy={detailTransactions}
            count={count1}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Claimall;
