import web3NoAccount from "./web3";
import DopAbi from "./dopClaimAbi.json";

const getContract = (abi, address, web3) => {
  const _web3 = web3;
  return new _web3.eth.Contract(abi, address);
};

export const dopContract = (address, web3) => {
  return getContract(DopAbi, address, web3);
};