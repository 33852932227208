import React from "react";
import "./header.scss";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { useWeb3React } from "@web3-react/core";

const Header = () => {
  const { login, logout } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const { account } = useWeb3React();

  const Logout = async () => {
    let locaWallet = localStorage.getItem("connectorId");
    await logout(locaWallet);
    localStorage.clear();
    history.push("/");
    window.location.reload()
  };

  const isHomePage = location.pathname === "/";
  return (
    <section className="mainheader">
      <div className="custom-container">
        <div className="innerheader">
          <a
            onClick={() => {
              window?.location?.reload();
            }}
          >
            <img src="\logo.svg" alt="logoimg" className="logoimg" />
          </a>
          <div className="twice-btn">
            {account ? (
              <div
                onClick={() => Logout()}
                className="disconnectbtn cursor-pointer"
              >
                Disconnect
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
