// export const API_URL = 'http://192.168.20.25:8000/'
// export const API_URL = 'http://192.168.0.245:8080/v1/'
// export const API_URL1 = "http://192.168.20.66:8000/"
// export const API_URL = 'http://ec2-54-191-120-95.us-west-2.compute.amazonaws.com:8050/'
export const API_URL = "https://claim-api.gems.vip/";
// export const API_URL = "http://192.168.20.11:8050/"

// main wala
// export const API_URL = "http://192.168.20.30:8070/"

// export const API_URL = "https://apivote.dop.org/"

// export const API_URL = "http://192.168.20.198:8000/"
// export const BASE_URL = 'https://tomipay-staging.tomi.com/';
// export const CoingekoBaseURL = 'https://pro-api.coingecko.com/api/v3';
// export const eth = "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
// export const API_URL = 'http://192.168.0.154:8081/v1/'
