import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "./useWeb3";
import environment from "../utils/Environment";
import { dopContract } from "../utils/contractHelpers";
import axios from "axios";

export const ClaimDops = () => {
  const { account, chainId } = useWeb3React();
  const web3 = useWeb3();
  const stakingAddress = environment.dop;
  const contract = dopContract(stakingAddress, web3);

  const Mint = useCallback(
    async (
      quantity,
      account,
      proof,
      ids,
      nftQuantity,
      isKycRequired,
      claimNFT,
      v,
      r,
      s,
      callback
    ) => {
      console.log(
        "quantity payload",
        account,
        quantity,
        proof,
        ids,
        nftQuantity,
        claimNFT,
        v,
        r,
        s
      );
      try {
        // let gasPrice = await web3.eth.getGasPrice();
        // gasPrice = parseInt(gasPrice) + 10000000000;

        var gasFunPrice;
        web3.eth.getGasPrice().then((result) => {
          var result2 = parseInt(result) + 3000000000;
          gasFunPrice = result2.toString();
        });
        const gas = await contract.methods
          .claimGems(quantity,
            proof,
            ids,
            nftQuantity,
            claimNFT,
            v,
            r,
            s)
          .estimateGas({ from: account });
        const gaslimit = gas + parseInt(15000)
        const claimed = await contract.methods
          .claimGems(
            quantity,
            proof,
            ids,
            nftQuantity,
            claimNFT,
            v,
            r,
            s
          )
          .send({ from: account, gas: gaslimit, gasPrice: gasFunPrice })
          .on('transactionHash', (tx) => {
            console.log("tx:::", tx)
            callback()
            return tx
          });
        // return true;
      } catch (e) {
        return e;
      }
    },
    [contract]
  );

  return { Claim: Mint };
};

export default ClaimDops;
