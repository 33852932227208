import React from "react";
import { ToastContainer } from "react-bootstrap";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Landing from "./components/landing/Landing";
import useEagerConnect from "./hooks/useEagerConnect";
import Claimall from "./components/Claimall/Claimall";
import Kycmain from './components/Kycmain/Kycmain';
import Kyc from './components/Kyc/Kyc';
import Kyctwo from './components/Kyc/Kyctwo';
import Maintainence from "./components/Maintainence/Maintainence";

function App() {
  useEagerConnect();
  return (
    <>
      <ToastContainer
        autoClose={5000}
        style={{ fontSize: 12, fontWeight: 300 }}
        theme="light"
        position="top-center"
      />
      <Router>
        <Route exact path="/" render={() => <Landing />} />
        <Route exact path="/claimall" render={() => <Claimall />} />
      </Router>
    </>
  );
}

export default App;
